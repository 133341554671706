import getImage from "~/utils/images/getImage";

const getRealSizeImage = async (imageId: string): Promise<{ width: number; height: number }> => new Promise((resolve, reject) => {
  const imageLink = getImage(imageId, 'webp');

  if (imageLink) {
    const image = document.createElement('img');

    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height
      });
    };

    image.src = imageLink;
  }
});

export {
  getRealSizeImage
};
