<template>
  <div
    class="image-svg-stage__root"
    :class="{
      '--center': Boolean(imageId)
    }"
  >
    <section
      v-if="Boolean(imageId)"
      id="image-svg-stages"
      ref="imageSvgRef"
      class="image-svg"
    >
      <nuxt-img
        v-if="Boolean(imageId)"
        id="image-svg-stages__image"
        class="image-svg__image"
        :class="{'is-image-ready': isImageLoaded}"
        :src="imageId"
        width="1280px"
        loading="lazy"
        placeholder
        @load="onImageLoad"
      />
      <svg
        ref="svgRef"
        class="image-svg__svg"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          v-for="(info, key) in pointsInfo"
          v-show="Boolean(info.info.status !== 'sold')"
          :key="`polygon-${key}`"
          ref="polygonListRef"
          :points="`${info.coordinates}`"
          class="image-svg__polygon"
          :class="{
            '--disabled': Boolean(info.info.disabled),
            '--booked': Boolean(info.info.status === 'booked' || info.info.status === 'unavailable'),
            '--sold': Boolean(info.info.status === 'sold')
          }"

          @mouseover="(event) => mouseoverPolygon(event, info, key)"
          @mouseleave="mouseleavePolygon"
          @click="() => clickPolygon(info)"
        />
      </svg>
      <template v-if="activeInfoIndexMain && Object.keys(activeInfoIndexMain).length">
        <div
          class="hover-content"
          :class="{'--fixed': activeStage === 0}"
          :style="`left: ${activeInfoIndexMain.hoverPosition?.[0]}px; top: ${activeInfoIndexMain.hoverPosition?.[1]}px`"
        >
          <div
            v-if="activeInfoIndexMain.title"
            class="hover-content__title"
            v-html="activeInfoIndexMain.title"
          />
          <div
            v-if="activeInfoIndexMain.description"
            class="hover-content__description"
          >
            {{ activeInfoIndexMain.description }}
          </div>
          <div
            v-if="activeInfoIndexMain.caption"
            class="hover-content__caption"
          >
            {{ activeInfoIndexMain.caption }}
          </div>
          <ul
            v-if="activeInfoIndexMain.list && activeInfoIndexMain.list.length > 0"
            class="hover-content__params"
          >
            <li
              v-for="(param, index) in activeInfoIndexMain.list"
              :key="`activeInfoIndex.params-${index}`"
            >
              {{ param }}
            </li>
          </ul>
        </div>
      </template>
    </section>
    <section
      v-if="Boolean(!imageId)"
      class="image-svg__not-image"
    >
      <div
        v-for="(info, key) in pointsNotImage as PointsNotImageType[]"
        :key="'not-image-svg-item-' + key"
        @click="() => clickPolygon(info)"
      >
        {{ info?.label || info?.name }}
      </div>
    </section>
    <section
      v-if="tagsEntrance.length > 0"
      class="image-svg__not-image"
    >
      <div
        v-for="(info, key) in tagsEntrance"
        :key="'not-image-svg-item-entrance-' + key"
        :class="{'active': key === activeEntrance}"
        @click="() => changeActiveEntrance(key, info)"
      >
        {{ info?.label || info?.name }}
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import type {ICategory} from '#sitis/internal/controllers/categories/models/Category';
import {Category} from '#sitis/internal/controllers/categories/models/Category';
import {getRealSizeImage} from '~/utils';
import type {ApartmentDataType} from '~/cms/blocks/VisualChoiceFloorApartmentBlock.vue';
import type {BuildingStateType} from '~/consts/building-state';
import buildingState from '~/consts/building-state';
import {type ComputedRef, useRouter} from '#imports';
import type {FlatStatusType} from '~/consts/flat-status';
import flatStatus from '~/consts/flat-status';

type PointsNotImageType = {
  label: string;
  name?: string;
  category_id: number | string;
  info: {
    status: string;
    category_id: number | string;
  };
}

type PointsInfoInfoType = {
  caption?: string;
  description?: string;
  list?: string[];
  status?: string;
  title?: string;
  disabled?: boolean;
  productUrl?: string;
}

type PointsInfoType = {
  category_id: number;
  coordinates: string[];
  info: PointsInfoInfoType;
}

type PointsListType = {
  category_id: number;
  entrance_id?: number;
  coordinates: {
    x: string;
    y: string;
  }[];
}

const props = defineProps<{
  category: ICategory | null | undefined;
  apartmentData: ApartmentDataType[];
  activeStage: number;
  valueType: number;
}>();

const emit = defineEmits<{
  changeStageNext: [id: number],
  changeActiveEntrance: [slug: string],
}>();

type HTMLElementRectType = {
  left: number,
  top: number,
  right: number,
  bottom: number,
  x: number,
  y: number,
  width: number,
  height: number,
}

const {$priceFormat, $handleWordDeclension} = useNuxtApp();
const pointsNotImage = ref<ICategory[] | PointsNotImageType[] | null>(null);
const pointsInfo = ref<PointsInfoType[] | []>([]);
const isImageLoaded = ref<Boolean>(false)

const imagePlanningRef = ref<HTMLImageElement>();
const imageSvgRef = ref<HTMLDivElement>();
const svgRef = ref<SVGElement>();
const polygonListRef = ref<HTMLElement[]>();
const activeEntrance = ref(0);

const svgRefRect: ComputedRef<HTMLElementRectType> = computed(() => {
  return svgRef.value?.getBoundingClientRect() ?? {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }
})

const imageSvgRefRect: ComputedRef<HTMLElementRectType> = computed(() => {
  return imageSvgRef.value?.getBoundingClientRect() ?? {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }
})

const activeInfoIndexMain = ref<any>({});

const points = computed((): PointsListType[] | undefined => {
  if (props.activeStage === 0) {
    return props.category?.dynamicFields?.house_areas || [];
  }

  if (props.activeStage === 1) {
    const isHowManuFloors = props.category?.dynamicFields.is_how_many_floors

    if (isHowManuFloors) {
      const children = props?.category?.dynamicFields?.children || [];

      return children[activeEntrance.value]?.entrance_areas;
    }

    return props.category?.dynamicFields?.floor_areas || [];
  }

  if (props.activeStage === 2) {
    return props.category?.dynamicFields?.property_areas || [];
  }
});

const imageId = computed(() => {
  if (props.activeStage === 0) {
    return String(props.category?.dynamicFields?.house_areas_image);
  }

  if (props.activeStage === 1) {
    const isHowManyFloors = props.category?.dynamicFields.is_how_many_floors

    if (isHowManyFloors) {
      const children = props?.category?.dynamicFields?.children || [];
      return String(children[activeEntrance.value]?.entrance_areas_image);
    }

    return String(props.category?.dynamicFields?.floor_areas_image);
  }

  if (props.activeStage === 2) {
    return String(props.category?.imageId);
  }
});

const tagsEntrance = computed(() => {
  if (!(props.activeStage === 1 && props.category?.dynamicFields.is_how_many_floors)) {
    return [];
  }
  return props.category?.dynamicFields?.children
    .map((t: any) => new Category(t).toJSON());
});

const setPointsNotImage = () => {
  let list: ICategory[] | PointsNotImageType[] | null = [];

  const categoryChildren: ICategory[] = props.category?.dynamicFields?.children
    .map((t: any) => new Category(t).toJSON());

  if (props.activeStage === 0) {
    list = [...(categoryChildren)].map((item) => ({
      label: `Дом ${item.name}`,
      category_id: item.id,
      info: {
        status: (item?.dynamicFields?.building_state || '').toLowerCase(),
        category_id: item.id
      }
    }));
  }

  if (props.activeStage === 1) {
    list = [...categoryChildren];
  }

  if (props.activeStage === 2) {
    const items = categoryChildren;
  }

  pointsNotImage.value = list;
};

const _productName = (product: ICategory) => {
  const productNumber = product.name;
  const characteristics = product?.dynamicFields?.short_characteristics || [];
  const characteristicGroupId = product?.dynamicFields?.characteristic_group_id;
  const productRooms = (characteristics || []).find((t: any) => t.slug === 'rooms')?.values?.[0] || null;

  let nameType = 'Квартира';
  if (characteristicGroupId === 2) {
    nameType = 'Паркинг';
  }
  if (characteristicGroupId === 3) {
    nameType = 'Кладовая';
  }
  if (characteristicGroupId === 4) {
    nameType = 'Коммерческое помещение';
  }
  if (characteristicGroupId === 5) {
    nameType = 'Машиноместо';
  }

  if (!productRooms) {
    return `${nameType} № ${productNumber}`;
  }
  if (productRooms.slug === 'studiya') {
    return `Квартира-студия №&nbsp;${productNumber}`;
  }

  return `${productRooms?.value}-к ${nameType.toLocaleLowerCase()} № ${productNumber}`;
};

const _productSquare = (product: ICategory) => {
  const characteristics = product?.dynamicFields?.short_characteristics || [];
  const propertyArea = characteristics.find((t: any) => t.slug === 'area-total')?.values?.[0]?.value || null;
  if (!propertyArea) {
    return;
  }

  return `Площадь: ${propertyArea} м²`;
};

const _productPriceCurrent = (product: ICategory) => {
  // TODO КАСТЫЛЬ С ЦЕНОЙ
  if (!product?.dynamicFields?.price || product?.dynamicFields?.price <= 0) {
    return;
  }
  return `Цена: ${$priceFormat({amount: product?.dynamicFields?.price})} р.`;
};

const _productStatus = (product: ICategory) => {
  const characteristics = product?.dynamicFields?.short_characteristics || [];
  const propertyArea = characteristics.find((t: any) => t.slug === 'status')?.values?.[0]?.slug || null;

  if (!propertyArea) {
    return;
  }

  return `Статус: ${flatStatus[propertyArea as FlatStatusType]}`;
};

const _getActiveInfoIndex = (point: any) => {
  let category: any = (props.category?.dynamicFields?.children || [])
    .map((t: any) => new Category(t).toJSON())
    .find((t: ICategory) => t.id === (point.category_id || point.entrance_id || point.product_id));
  if (props.activeStage === 1 && props.category?.dynamicFields.is_how_many_floors) {
    category = (props.category?.dynamicFields?.children || [])[activeEntrance.value];
  }

  if (!category) {
    return null;
  }

  const isHowManyFloors = Boolean(props.category?.dynamicFields.is_how_many_floors);

  const activeInfoIndex: PointsInfoInfoType = {};

  // Выбор дома
  if (props.activeStage === 0) {
    activeInfoIndex.title = category.name;
    activeInfoIndex.description = category?.dynamicFields?.building_state
      ? `Статус: ${buildingState[(category?.dynamicFields?.building_state).toLowerCase() as BuildingStateType]}`
      : '';

    activeInfoIndex.status = (category?.dynamicFields?.building_state || '').toLowerCase();

    if (category?.dynamicFields?.development_end_quarter_quarter && category?.dynamicFields?.development_end_quarter_year) {
      activeInfoIndex.caption = `Срок сдачи: ${category?.dynamicFields?.development_end_quarter_quarter} кв ${category?.dynamicFields?.development_end_quarter_year} г.`;
    }

    const categoryApartmentData: any[] = [];
    let categoryApartmentRooms: any[] = [];

    category?.dynamicFields?.children.forEach((item: any) => {
      const items = props.apartmentData.filter((t) => t.category_id === item.id);
      categoryApartmentData.push(...items);
    });

    categoryApartmentData.forEach((t) => {
      if (!categoryApartmentRooms.includes(t.rooms)) {
        categoryApartmentRooms.push(t.rooms);
      }
    });

    categoryApartmentRooms = categoryApartmentRooms
      .sort((a, b) => {
        if (a === 'Ст.') {
          a = '0';
        }
        if (b === 'Ст.') {
          b = '0';
        }

        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })

      .map((room) => {
        const minPrice = Math.min(...categoryApartmentData.filter((t) => t.rooms === room).map((t) => Number.parseFloat(t.min_price)));
        let labelRoom = room === 'Ст.' ? room : `${room}-к`;
        if (room === '0') {
          labelRoom = categoryApartmentData.find((t) => t.rooms === room)?.type;
        }

        return `${labelRoom} — ${$priceFormat({amount: minPrice, decimalCount: 0, decimal: '.'})} р.`;
      });

    activeInfoIndex.list = categoryApartmentRooms;
  }

  // Выбор этажа
  if (props.activeStage === 1 && isHowManyFloors) {
    const categoryApartments = props.apartmentData.filter((t) => t.category_id === Number(point.entrance_id));
    const categoryApartmentsCount = categoryApartments?.[0]?.floor_products_count || 0;
    const categoryName = (category?.children || []).find((t: any) => t.id === point.entrance_id)?.name;

    let labels = ['квартира', 'квартиры', 'квартир'];
    if (props.valueType === 2) {
      labels = ['паркинг', 'паркинг', 'паркингов'];
    }
    if (props.valueType === 3) {
      labels = ['кладовая', 'кладовые', 'кладовых'];
    }
    if (props.valueType === 4) {
      labels = ['коммерческое помещение', 'коммерческих помещения', 'коммерческих помещений'];
    }
    if (props.valueType === 5) {
      labels = ['машиноместо', 'машиноместа', 'машиномест'];
    }

    let caption = `Нет свободных ${[...labels].pop()}`;

    if (categoryApartmentsCount > 0) {
      caption = [
        categoryApartmentsCount,
        $handleWordDeclension(categoryApartmentsCount, ['свободная', 'свободные', 'свободных']),
        $handleWordDeclension(categoryApartmentsCount, labels)
      ].join(' ');
    }

    let categoryApartmentRooms: string[] = [];
    categoryApartments.forEach((t) => {
      if (!categoryApartmentRooms.includes(t.rooms)) {
        categoryApartmentRooms.push(t.rooms);
      }
    });

    categoryApartmentRooms = categoryApartmentRooms
      .sort((a, b) => {
        if (a === 'Ст.') {
          a = '0';
        }
        if (b === 'Ст.') {
          b = '0';
        }

        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })
      .map((room) => {
        const minPrice = Math.min(...categoryApartments.filter((t) => t.rooms === room).map((t) => Number.parseFloat(t.min_price)));
        const labelRoom = room ? room === 'Ст.' ? room : `${room}-к` : null;

        return [
          labelRoom,
          Boolean(!!minPrice && minPrice > 0) && `от ${$priceFormat({
            amount: minPrice,
            decimalCount: 0,
            decimal: '.'
          })} р.`
        ].filter((t) => !!t).join(' — ');
      });

    activeInfoIndex.title = categoryName;
    activeInfoIndex.caption = caption;
    activeInfoIndex.disabled = Boolean(categoryApartmentsCount <= 0);
    activeInfoIndex.list = categoryApartmentRooms;
  }
  if (props.activeStage === 1 && !isHowManyFloors) {
    const categoryApartments = props.apartmentData.filter((t) => t.category_id === Number(category.id));
    const categoryApartmentsCount = categoryApartments?.[0]?.floor_products_count || 0;

    let labels = ['квартира', 'квартиры', 'квартир'];

    if (props.valueType === 2) {
      labels = ['паркинг', 'паркинг', 'паркингов'];
    }
    if (props.valueType === 3) {
      labels = ['кладовая', 'кладовые', 'кладовых'];
    }
    if (props.valueType === 4) {
      labels = ['коммерческое помещение', 'коммерческих помещения', 'коммерческих помещений'];
    }
    if (props.valueType === 5) {
      labels = ['машиноместо', 'машиноместа', 'машиномест'];
    }

    let caption = `Нет свободных ${[...labels].pop()}`;

    if (categoryApartmentsCount > 0) {
      caption = [
        categoryApartmentsCount,
        $handleWordDeclension(categoryApartmentsCount, ['свободная', 'свободные', 'свободных']),
        $handleWordDeclension(categoryApartmentsCount, labels)
      ].join(' ');
    }

    let categoryApartmentRooms: string[] = [];
    categoryApartments.forEach((t) => {
      if (!categoryApartmentRooms.includes(t.rooms)) {
        categoryApartmentRooms.push(t.rooms);
      }
    });

    categoryApartmentRooms = categoryApartmentRooms
      .sort((a, b) => {
        if (a === 'Ст.') {
          a = '0';
        }
        if (b === 'Ст.') {
          b = '0';
        }

        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })
      .map((room) => {
        const minPrice = Math.min(...categoryApartments.filter((t) => t.rooms === room).map((t) => Number.parseFloat(t.min_price)));
        const labelRoom = room ? room === 'Ст.' ? room : `${room}-к` : null;

        return [
          labelRoom,
          Boolean(!!minPrice && minPrice > 0) && `от ${$priceFormat({
            amount: minPrice,
            decimalCount: 0,
            decimal: '.'
          })} р.`
        ].filter((t) => !!t).join(' — ');
      });

    activeInfoIndex.title = category.name;
    activeInfoIndex.caption = caption;
    activeInfoIndex.disabled = Boolean(categoryApartmentsCount <= 0);
    activeInfoIndex.list = categoryApartmentRooms;
  }

  // Выбор квартиры
  if (props.activeStage === 2) {
    activeInfoIndex.title = _productName(category);
    activeInfoIndex.productUrl = category?.url || '#';
    activeInfoIndex.status = (category?.dynamicFields?.short_characteristics || []).find((t: any) => t.slug === 'status')?.values?.[0]?.slug || null;

    const list = [];

    const productSquare = _productSquare(category);
    if (productSquare) {
      list.push(productSquare);
    }

    const productPriceCurrent = _productPriceCurrent(category);
    if (productPriceCurrent) {
      list.push(productPriceCurrent);
    }

    const productStatus = _productStatus(category);
    if (productStatus) {
      list.push(productStatus);
    }

    activeInfoIndex.list = list;
  }

  return activeInfoIndex;
};

const onImageLoad = () => {
  imagePlanningRef.value?.addEventListener('load', () => {
    setPoints()
    isImageLoaded.value = true
  })
}

const onResize = () => {
  setPoints()
}

const setPoints = async () => {
  const IMAGE_PLACEHOLDER_HEIGHT = 150

  const setSvgContainerWidth = (newWidth: number) => {
    if (svgRef.value) {
      svgRef.value.style.width = `${newWidth}px`;
    }
  }

  if (!imageId.value) {
    setPointsNotImage();

    return;
  }

  let scaleHeight = 1
  const {height} = await getRealSizeImage(imageId.value)
  const imagePlanningClientHeight = imagePlanningRef.value?.clientHeight ?? 0
  const imagePlanningClientWidth = imagePlanningRef.value?.clientWidth ?? 0
  const imagePlanningNaturalHeight = imagePlanningRef.value?.naturalHeight ?? 0

  if (imagePlanningClientHeight && height) {
    scaleHeight = height <= IMAGE_PLACEHOLDER_HEIGHT
      ? Number((imagePlanningClientHeight / imagePlanningNaturalHeight).toFixed(4))
      : Number((imagePlanningClientHeight / height).toFixed(4))
  }

  setSvgContainerWidth(imagePlanningClientWidth)

  if (points.value) {
    // @ts-ignore
    pointsInfo.value = points.value!.map((point: PointsListType) => {
      const info = _getActiveInfoIndex(point);

      if (info) {
        return {
          category_id: point.category_id || point.entrance_id,
          coordinates: point.coordinates.map((t) => {
            return `${(+t.x * (scaleHeight))} ${(+t.y * (scaleHeight))}`
          }),
          info: info
        } as PointsInfoType;
      }
      return null;
    }).filter((t) => !!t);
  }
};

const mouseoverPolygon = (event: MouseEvent, info: PointsInfoType, refIdx: number) => {
  const {offsetY, clientY} = event;
  const polygonListElement = polygonListRef?.value?.[refIdx]
  const polygonListElementRect: HTMLElementRectType = polygonListElement?.getBoundingClientRect() ?? {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }

  let hoverPosition: number[] = [];

  if (polygonListElement) {
    const {left: polygonLeft} = polygonListElementRect;
    hoverPosition = [(polygonLeft - 250), (clientY - 50)];
  }

  if (props.activeStage === 1) {
    const minLeft = Math.min(...info.coordinates.map((t) => Number.parseFloat(t.split(' ')?.[0])));

    hoverPosition = [(minLeft - 220), (offsetY - (offsetY * 0.3))];
  }

  if (props.activeStage === 2) {
    const minTop = Math.min(...info.coordinates.map((t) => Number.parseFloat(t.split(' ')?.[1])));
    const maxLeft = Math.max(...info.coordinates.map((t) => Number.parseFloat(t.split(' ')?.[0])));

    if (svgRef.value && imageSvgRef.value) {
      const {width: svgWidth} = svgRefRect.value;
      const {width: imageSvgWidth} = imageSvgRefRect.value;

      const leftOffset = (imageSvgWidth - svgWidth) / 2;

      hoverPosition = [
        ((maxLeft + leftOffset) + (leftOffset * 0.03)),
        (minTop)
      ];
    }
  }

  activeInfoIndexMain.value = {
    ...info,
    ...info.info,
    hoverPosition: hoverPosition
  };
};

const clickPolygon = (info: any) => {
  if (props.activeStage > 0 && (
    info?.info?.disabled ||
    Boolean(info?.info?.status === 'booked') ||
    Boolean(info?.info?.status === 'sold') ||
    Boolean(info?.info?.status === 'unavailable')
  )) {
    return;
  }

  if (props.activeStage !== 2) {
    emit('changeStageNext', info.category_id);
    return;
  }
  if (info.info.productUrl) {
    useRouter().push(info?.info?.productUrl);
  }
};

const mouseleavePolygon = () => {
  activeInfoIndexMain.value = null;
};

const changeActiveEntrance = async (val: any, info: ICategory | null) => {
  emit('changeActiveEntrance', info?.slug ?? '');
  activeEntrance.value = val;
  await setPoints();
}

onMounted(() => {
  imagePlanningRef.value = document.getElementById('image-svg-stages__image') as HTMLImageElement ?? undefined;
  window.addEventListener('resize', onResize)
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})
</script>

<style lang="scss" scoped>
@import "@/assets/scss/media";

.image-svg-stage__root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &.--center {
    align-items: center;
    justify-content: center;
  }
}

.image-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-height: 100%;
  position: relative;

  &:hover {
    .image-svg__polygon {
      opacity: 0.4;
    }
  }
}

.image-svg__image {
  margin: auto;
  width: auto;
  height: 100%;
  border-radius: 20px;
  opacity: 0;

  &.is-image-ready {
    opacity: 1;
  }
}

.image-svg__svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
}

.image-svg__polygon {
  fill: #3C1D6D;
  opacity: 0;
  cursor: pointer;

  &:hover {
    fill: #FFC115;
    opacity: 0.7;
  }

  &.--disabled {
    fill: #E2DDE9;
    cursor: not-allowed;
  }

  &.--booked {
    fill: #000;
    cursor: not-allowed;
  }

  &.--sold {
    fill: #E30613;
    cursor: not-allowed;
  }
}

.image-svg__not-image {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: absolute;
  left: 140px;
  top: 10px;
  gap: 8px;

  & > * {
    border-radius: 46px;
    border: 2px solid #FFC115;
    background: rgb(255 255 255 / 50%);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 3%), 0 3.122px 6.244px 0 rgb(0 0 0 / 3%), 0 10.927px 10.927px 0 rgb(0 0 0 / 3%), 0 24.976px 14.049px 0 rgb(0 0 0 / 2%), 0 43.707px 17.171px 0 rgb(0 0 0 / 0%), 0 68.683px 18.732px 0 rgb(0 0 0 / 0%);
    padding: 3.5px 15px;
    box-sizing: border-box;
    cursor: pointer;
    color: #3C1D6D;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.16px;

    &.active {
      background-color: #FFC115;
      color: white;
    }
  }
}

.hover-content {
  background: #FFF;
  box-shadow: 20px 30px 70px rgb(43 42 41 / 25%);
  border-radius: 10px;
  padding: 16px;
  position: absolute;
  z-index: 5;
  user-select: none;
  pointer-events: none;
  min-width: 220px;

  &.--fixed {
    position: fixed;
  }
}

.hover-content__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #3C1D6D;
}

.hover-content__description {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #3C1D6D;
}

.hover-content__caption {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #B1A5C5;
}

.hover-content__params {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #3C1D6D;

    &:first-child {
      margin-top: 0;
    }
  }
}

@include media('md') {
  .image-svg__image {
    border-radius: 8px;
  }
}
</style>
